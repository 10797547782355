import { Dispatch, Fragment, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Box, CheckboxesField, Message } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { TeamBulkCreatePayload, TeamCreatePayload, useCreateTeam } from 'main/services/queries/use-teams'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import {
  CheckboxFieldControlled,
  FileInputField,
  FormModal,
  RadioboxGroupField,
  TextInputField,
  UserSelectField
} from 'main/components/shared/form'
import { EmptyRoleType } from 'main/services/queries/types'
import { useRoleTypes } from 'main/services/queries/use-role-types'

type CreateTeamFormType = yup.InferType<typeof formSchema>

type CreateTeamModalProps = {
  open?: boolean
  onClickClose?: () => void
  setOpen?: Dispatch<SetStateAction<boolean>>
}

export const CreateCentralTeamModal = ({ open, setOpen, onClickClose }: CreateTeamModalProps) => {
  const { t } = useLanguage('centralTeams', { keyPrefix: 'createModal' })
  const { account } = useAccount()
  const { data, isLoading } = useRoleTypes({ contextTypeId: 10, enabled: true })
  const roleTypes = data?.role_types

  const mutationSingle = useCreateTeam<'single' | 'bulk'>('single')
  const mutationBulk = useCreateTeam<'single' | 'bulk'>('bulk')

  const closeModal = () => {
    onClickClose?.()
    setOpen?.(false)
  }

  const handleSubmit = (data: ReturnType<typeof transformer>) => {
    const mutation = data.hasOwnProperty('file') ? mutationBulk : mutationSingle
    return mutation.mutateAsync(data)
  }

  const defaultValues = {
    account_id: account?.id,
    create_type: 'single',
    overwrite: false
  }

  return (
    <FormModal<CreateTeamFormType, TeamCreatePayload | TeamBulkCreatePayload>
      open={open}
      title={t('title')}
      successMessage={t('successMessage')}
      defaultValues={defaultValues}
      onClose={closeModal}
      onSubmit={handleSubmit}
      transformer={transformer}
      schema={formSchema}
      confirmIcon="add"
      loading={isLoading}
      confirmText={t('submitNew')}
    >
      {roleTypes && <FormFields roleTypes={roleTypes} />}
    </FormModal>
  )
}

const FormFields = ({ roleTypes }: { roleTypes: EmptyRoleType[] }) => {
  const { t } = useLanguage('centralTeams', { keyPrefix: 'createModal' })
  const { account } = useAccount()
  const { watch, register } = useFormContext<CreateTeamFormType>()
  const createType = watch('create_type')

  return (
    <>
      <RadioboxGroupField<CreateTeamFormType>
        name="create_type"
        label={t('fields.createType.label')}
        direction="row"
        options={[
          { label: t('fields.createType.single'), value: 'single' },
          { label: t('fields.createType.bulk'), value: 'bulk' }
        ]}
      />
      {createType === 'single' ? (
        <>
          <TextInputField<CreateTeamFormType> name="name" label={t('fields.name.label')} />
          {roleTypes?.map((roleType, index) => {
            return (
              <Fragment key={`role_types.${roleType.id}`}>
                <UserSelectField<CreateTeamFormType>
                  name={`role_types.${index}.users`}
                  accountId={account?.id}
                  label={roleType.name}
                  helpText={roleType.description || undefined}
                  // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
                  required={false}
                />
                <input type="hidden" {...register(`role_types.${index}.id` as const)} defaultValue={roleType.id} />
              </Fragment>
            )
          })}
        </>
      ) : (
        <>
          <Box margin={{ bottom: '16px' }}>
            <FileInputField<CreateTeamFormType> name="file" accept="text/csv" />
          </Box>
          <CheckboxesField label={t('fields.overwrite.header')}>
            <CheckboxFieldControlled<CreateTeamFormType> name="overwrite" label={t('fields.overwrite.label')} />
          </CheckboxesField>
          <Box margin={{ bottom: '16px' }}>
            <Message message={t('uploadHelpMessage')} />
          </Box>
        </>
      )}
    </>
  )
}

const formSchema = yup.object({
  create_type: yup.string().oneOf(['single', 'bulk']),
  account_id: yup.number().required(),
  name: yup.string().when(['create_type'], {
    is: (create_type: string) => create_type === 'bulk',
    then: schema => schema.notRequired(),
    otherwise: schema => schema.required()
  }),
  file: yup.mixed().when(['create_type'], {
    is: (create_type: string) => create_type === 'bulk',
    then: schema => schema.required(),
    otherwise: schema => schema.notRequired()
  }),
  overwrite: yup.bool().required(),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      users: yup.array().of(
        yup.object({
          id: yup.number().required()
        })
      )
    })
  )
})

const transformer = (data: CreateTeamFormType): TeamCreatePayload | TeamBulkCreatePayload => {
  if (data.create_type === 'single') {
    const teamPayload: TeamCreatePayload = {
      team: {
        name: data.name ?? '',
        account_id: data.account_id,
        roles:
          data.role_types?.flatMap(role =>
            (role.users || []).map(user => ({
              role_type_id: role.id,
              subject_id: user.id,
              subject_type: 'User'
            }))
          ) ?? []
      }
    }
    return teamPayload
  } else {
    const bulkPayload: TeamBulkCreatePayload = {
      file: data.file,
      overwrite: data.overwrite,
      account_id: data.account_id
    }
    return bulkPayload
  }
}
