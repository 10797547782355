import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient
} from 'react-query'
import { produce } from 'immer'
import { getUnixTime } from 'date-fns'
import { unescape } from 'lodash'

import { apiClient, ApiError } from '../api'
import { useSetPermissions } from './use-permissions'
import { QueryKeys } from './query-keys'
import { CentralTeam, Role, Team } from './types'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import {
  CentralTeamBulkCreateResponse,
  CentralTeamCreateResponse,
  CentralTeamShowResponse
} from '../api/data-providers/workspace/central-team-types'

const DEFAULT_LIMIT = 100

const DEFAULTS = {
  highlight: true,
  order: 'name',
  sort_direction: 'asc',
  per_page: DEFAULT_LIMIT
}

export type TeamCreatePayload = {
  team: {
    account_id: number
    name: string
    roles: Role[]
  }
}

export type TeamUpdatePayload = {
  team: {
    account_id: number
    name: string
    highlight: boolean
    color: string
    roles: Role[]
  }
}

export type TeamBulkCreatePayload = {
  account_id: number
  file: any
  overwrite: boolean
}

type TeamsResponse = {
  meta: {
    permissions: any
    total_results: number
  }
  teams: Team[]
}

export function useTeams({
  accountId,
  params = {},
  options = { enabled: true }
}: {
  accountId?: string | number
  params?: Record<string, any>
  options?: UseInfiniteQueryOptions<TeamsResponse, ApiError>
}) {
  const setPermissions = useSetPermissions('teams')

  const { q, ...restParams } = params
  const serverParams = { ...DEFAULTS, ...restParams, query: q }

  const getTeams = async (offset: number = 0) => {
    const { data } = await apiClient.get<TeamsResponse>({
      url: `accounts/${accountId}/teams`,
      params: { ...serverParams, offset }
    })

    return data
  }

  return useInfiniteQuery<TeamsResponse, ApiError>(
    [QueryKeys.Teams, String(accountId), serverParams],
    ({ pageParam = 0 }) => getTeams(pageParam),
    {
      getNextPageParam: (lastGroup, allGroups) => {
        const totalResultCount = allGroups[0]?.meta?.total_results
        const lastOffset = allGroups.length * serverParams.per_page

        return totalResultCount > serverParams.per_page && totalResultCount > lastOffset
          ? lastOffset + serverParams.per_page
          : undefined
      },

      onSuccess: response => {
        setPermissions(response.pages[0].meta.permissions)
      },

      ...options,
      enabled: options.enabled && !!accountId
    }
  )
}

export const useTeam = (teamId: number) => {
  return useQuery<CentralTeamShowResponse, ApiError>(
    ['team', String(teamId)],
    async () => {
      const { data } = await apiClient.get<CentralTeamShowResponse>({
        url: `teams/${teamId}`
      })

      data.team.name = unescape(data.team.name)

      return data
    },
    {
      cacheTime: 0
    }
  )
}

type CreateTeamType = 'single' | 'bulk'
type CreateTypePayload<CreateType extends CreateTeamType> = CreateType extends 'bulk'
  ? TeamBulkCreatePayload
  : TeamCreatePayload
type CreateTypeResponse<CreateType extends CreateTeamType> = CreateType extends 'bulk'
  ? CentralTeamBulkCreateResponse
  : CentralTeamCreateResponse

interface TeamPage {
  teams: CentralTeam[]
  meta: any
}

type CentralTeamListType = {
  pages: { teams: CentralTeam[] }[]
  pageParams: any[]
}

export function useCreateTeam<
  CreateType extends CreateTeamType,
  PayloadType extends CreateTypePayload<CreateType> = CreateTypePayload<CreateType>,
  ResponseType extends CreateTypeResponse<CreateType> = CreateTypeResponse<CreateType>
>(createType: CreateType) {
  const queryClient = useQueryClient()

  return useMutation<ResponseType, Error, PayloadType>(
    'create-team',
    async (payload: PayloadType): Promise<ResponseType> => {
      let requestData: any

      if (createType === 'bulk') {
        const formData = new FormData()
        const bulkPayload = payload as TeamBulkCreatePayload
        if (bulkPayload.file instanceof File) {
          formData.append('file', bulkPayload.file)
        } else {
          throw new Error('Invalid file')
        }
        formData.append('account_id', bulkPayload.account_id.toString())
        formData.append('overwrite', bulkPayload.overwrite.toString())
        requestData = formData
      } else {
        requestData = payload
      }

      const { data } = await apiClient.post<PayloadType, ResponseType>({
        url: 'teams',
        data: requestData,
        ...(createType === 'bulk' && {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      })

      if (!data) {
        // NOTE: dont know what to put here, but gives a TS error if I remove this check
        throw new Error('No data returned from API')
      }

      return data
    },
    {
      onSuccess: response => {
        if (JSON.stringify(response) === '{}') {
          // NOTE: bulk responses return an empty object, the whole list needs to be refreshed
          queryClient.invalidateQueries('teams')
          return
        }
        const responseTeam = (response as CentralTeamCreateResponse).team
        const queryCache = queryClient.getQueryCache()
        const queries = queryCache.findAll({
          queryKey: ['teams', String(responseTeam.account_id)]
        })
        const activeQuery = queries.find(q => q.isActive())
        const activeKey = activeQuery?.queryKey

        if (activeKey) {
          queryClient.setQueryData(activeKey, (oldData: CentralTeamListType | undefined): CentralTeamListType => {
            if (!oldData) {
              throw new Error('Data is undefined. Cannot proceed.')
            }
            return produce(oldData, draft => {
              const lastPage = draft.pages[draft.pages.length - 1] as TeamPage
              const lastPageTeams = lastPage?.teams
              if (lastPage?.meta?.total_results !== undefined) {
                lastPage.meta.total_results++
              }

              lastPageTeams.push({
                id: responseTeam.id,
                name: unescape(responseTeam.name),
                color: responseTeam.color,
                users_count: responseTeam.users_count,
                highlight: responseTeam.highlight,
                name_display: unescape(responseTeam.name),
                updated_at: getUnixTime(new Date()), // just put now since isn't sending the created_at date
                subject_type: 'Team',
                account_id: responseTeam.account_id
              })
            })
          })
        }
      }
    }
  )
}

export const useUpdateTeam = (
  id: number,
  options: UseMutationOptions<CentralTeamShowResponse, Error, TeamUpdatePayload> = {}
) => {
  const queryClient = useQueryClient()
  const { closeRightPanel } = useSetActiveRightPanelState()

  return useMutation<CentralTeamShowResponse, ApiError, TeamUpdatePayload>(
    'team-edit',
    async (payload: TeamUpdatePayload) => {
      const { data } = await apiClient.put({
        url: `teams/${id}`,
        data: payload
      })

      return data as CentralTeamShowResponse
    },
    {
      ...options,
      onSuccess: (response, variables, context) => {
        if (response.team.deleted) {
          // NOTE: could also manually splice from existing queryData, but this is easier & not a common action
          queryClient.invalidateQueries('teams')
          closeRightPanel()
          return
        }
        response.team.name = unescape(response.team.name)
        const queryCache = queryClient.getQueryCache()
        const queries = queryCache.findAll({ queryKey: ['teams', String(response.team.account_id)] })
        const activeQuery = queries.find(q => q.isActive())
        const activeKey = activeQuery?.queryKey

        const teamId = response.team.id
        if (activeKey) {
          queryClient.setQueryData(activeKey, (oldData: CentralTeamListType | undefined): CentralTeamListType => {
            if (!oldData) {
              throw new Error('Data is undefined. Cannot proceed.')
            }
            return produce(oldData, draft => {
              draft.pages.forEach(({ teams }) => {
                teams.forEach(team => {
                  if (team.id === teamId) Object.assign(team, response.team)
                })
              })
            })
          })
        }

        options?.onSuccess?.(response, variables, context)
      }
    }
  )
}
